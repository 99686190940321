import React, { forwardRef, useContext, useMemo } from 'react';
import { propsContext } from '../../context';
import { useClassName } from '../../hooks';
import type { VendorCardLargeProps } from '../../types';
import QuickResponderBadge from '../Badges/QuickResponderBadge';
import Content from '../Content';
import RequestPricingButton from '../RequestPricingButton';

const VendorCardBase = forwardRef<HTMLDivElement, VendorCardLargeProps>(
	(_props, ref) => {
		const { badges, size, id, quickResponder } = useContext(propsContext);
		const className = useClassName('container');

		const updatedBadges = useMemo(() => {
			return badges;
		}, [badges]);

		return (
			<>
				<section
					ref={ref}
					className={className}
					aria-labelledby={`vendor-id-${id}`}
					data-testid="vendor-card-base"
				>
					<Content badges={updatedBadges} />
					{size !== 'sm' && <RequestPricingButton />}
				</section>
				{quickResponder && <QuickResponderBadge />}
			</>
		);
	},
);

export default VendorCardBase;
